import React from "react";

import { buildLink } from "./buildLink";
import { localeToLang } from "./localeToLang";

export const getAlternateLinks = (pathname: Parameters<typeof buildLink>[0]) => {
  const locales = ["cz", "de", "pl", "lt", "sk"];

  return locales.map((locale) => (
    <link
      key={locale}
      rel="alternate"
      href={buildLink(pathname, locale, undefined, true)}
      hrefLang={localeToLang(locale)}
    />
  ));
};
