import React, { MouseEventHandler } from "react";

import { Link } from "app-components/link";
import { Breakpoint } from "modules/DesignSystem/breakpoint";
import { BannerCarouselSection } from "resources/AudiotekaApi";
import { deeplinkTranslator } from "modules/App/deeplinkTranslator";

import css from "./slide.module.scss";

interface Props {
  element: BannerCarouselSection["elements"][number];
  lazyLoading?: boolean;
  onClick?: MouseEventHandler;
}

export function Slide({ element, lazyLoading, onClick }: Props) {
  return (
    <Link
      className={css.slide}
      title={element.accessibility_label}
      onClick={onClick}
      {...deeplinkTranslator(element.deeplink, element.slug)}
    >
      <picture className={css.picture}>
        <source media={`(min-width: ${Breakpoint.sm}px)`} srcSet={`${element.banner_url}?w=600&auto=format`} />
        <img
          loading={lazyLoading ? "lazy" : null}
          alt={element.accessibility_label}
          src={`${element.banner_url}?w=550&auto=format`}
        />
      </picture>
    </Link>
  );
}
