import React from "react";
import Head from "next/head";

import { getActivationMethods, getHomeScreen } from "resources/AudiotekaApi";
import { getLocationInfo } from "utils/getLocationInfo";
import { getLocalePaths } from "utils/getLocalePaths";
import { getAlternateLinks } from "utils/getAlternateLinks";
import Home from "modules/Home";

export default function HomePage({ initialHomeScreen, activationMethods }) {
  return (
    <>
      <Head>{getAlternateLinks("home")}</Head>
      <Home initialHomeScreen={initialHomeScreen} activationMethods={activationMethods} />
    </>
  );
}

export const getStaticProps = async ({ params: { locale } }) => {
  const { catalogId } = getLocationInfo(locale);
  const initialHomeScreen = await getHomeScreen(catalogId);

  let hasOfferBoxes = false;
  let activationMethods = null;

  const reducedInitialHomeScreen = {
    ...initialHomeScreen,
    _embedded: {
      ...initialHomeScreen._embedded,
      "app:screen-section": initialHomeScreen._embedded["app:screen-section"].map((section) => {
        switch (section.type) {
          case "product-grid":
            return {
              ...section,
              _embedded: {
                ...section._embedded,
                "app:product": section._embedded["app:product"]
                  .slice(0, 20)
                  .map((product) => ({ ...product, _embedded: null })),
              },
            };
          case "product-grid-promo":
            return {
              ...section,
              _embedded: {
                ...section._embedded,
                "app:product": section._embedded["app:product"]
                  .slice(0, 5)
                  .map((product) => ({ ...product, _embedded: null })),
              },
            };
          case "placeholder":
            if (section.elements[0].placeholder_reference === "offer-boxes") {
              hasOfferBoxes = true;
            }
            return section;
          default:
            return section;
        }
      }),
    },
  };

  if (hasOfferBoxes) {
    const { methods } = await getActivationMethods(catalogId);
    activationMethods = methods;
  }

  return {
    props: { initialHomeScreen: reducedInitialHomeScreen, activationMethods },
    revalidate: 3600,
  };
};

export async function getStaticPaths() {
  return {
    paths: getLocalePaths(),
    fallback: false,
  };
}
