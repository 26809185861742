import React from "react";

import type { ActivationMethod, PlaceholderSection } from "resources/AudiotekaApi/AudiotekaApi.types";

import { AboutClub, Faq, HomeOfferBoxes, Onboarding, PageTitle } from "./components";

interface Props {
  section: PlaceholderSection;
  activationMethods?: ActivationMethod[];
}

export const Placeholder = ({ section, activationMethods }: Props) => {
  const {
    elements: [{ placeholder_reference: name }],
    tracking_id: trackingId,
  } = section;

  switch (name) {
    case "about-club":
      return <AboutClub />;
    case "faq":
      return <Faq />;
    case "offer-boxes":
      return <HomeOfferBoxes id={trackingId} activationMethods={activationMethods} />;
    case "onboarding":
      return <Onboarding />;
    case "page-title":
      return <PageTitle />;
    default:
      return null;
  }
};
